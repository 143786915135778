import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/index";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import PageNotFound from "./components/PageNotFound";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "cagdasaydemir-7040a.firebaseapp.com",
  projectId: "cagdasaydemir-7040a",
  storageBucket: "cagdasaydemir-7040a.appspot.com",
  messagingSenderId: "95708209006",
  appId: "1:95708209006:web:4b7787371db14c30932b7f",
  measurementId: "G-XP6G18YPTZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {
    logEvent(analytics, 'home-page visited');
  }, [])
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
