import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import "./index.scss";

const Layout = () => {


  
  return (
    <div  className="layout text-white min-vh-100 ">
      <Navbar />
      <section  className="page-container min-vh-100 ">
        <Outlet />
        
      </section>
    </div>
  );
};

export default Layout;
