import "./index.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoS from "../../assets/images/c-logo.svg";
import AnimatedLetters from "../AnimatedLetters";
import LogoTitle from "../../assets/images/c-logo.svg";
// import videoSrc from "../../assets/images/1 Hour Relaxing Beach Ambience with Retro Sunset.webm";
import LoaderPulse from "../Loader";

function Home() {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = "agdas".split('');
  const jobArray1 = "Software ".split('');
  const jobArray2 = "Developer".split('');

  useEffect(() => {
    document.title = 'Cagdas Aydemir - Home';
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);

    if (window.innerWidth >= 768) {
      setTimeout(() => {
        const hero = document.querySelector('.heroes');
        const page = document.querySelector('.page');
        const movementFactor = 40; // Adjust this value to control the movement sensitivity
        const windowWidth = window.innerWidth / movementFactor;
        const windowHeight = window.innerHeight / movementFactor;
    
        page.addEventListener('mousemove', (e) => {
          // Adjust the calculation by subtracting a percentage
          const mouseX = (e.clientX - window.innerWidth / 2) / windowWidth;
          const mouseY = (e.clientY - window.innerHeight / 2) / windowHeight;
          
          // Set a cap for the transformation to avoid moving too much
          const maxMovement = 20; // Set the max movement in percentage
          const clampedMouseX = Math.max(-maxMovement, Math.min(maxMovement, mouseX));
          const clampedMouseY = Math.max(-maxMovement, Math.min(maxMovement, mouseY));
    
          // Apply the adjusted transformation
          hero.style.transform = `translate3d(${-clampedMouseX}%, ${-clampedMouseY}%, 0)`;
        });
      }, 8000);
    }


  }, []);

  return (
    <>
   
      <div className="bg-img-container">
      <div className="bg-image"></div>
        {/* <img className="bg-image" id="bg-image" src={LogoS} alt="cagdas-logo" /> */}
      </div>
      <div className="page home-page">
        <div class="heroes">
        <div className="hero text set1">
         <div className="mt-auto mb-5">
         <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <span className={`${letterClass} _15`}> </span>
            <span className="name">
            <Link className="logo" to="/">
       
      <span>C</span>
      </Link>
            </span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={16}
            />
            <br />
            <div className="d-flex flex-wrap ">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray1}
                idx={21}
              />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray2}
                idx={30}
              />
            </div>
          </h1>
          <h2>Software Developer</h2>
         </div>
          <div className="d-flex flex-wrap gap-2">
          <Link to="/about" className="flat-button me-4">
            ABOUT ME
          </Link>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
          </div>
        </div>
        <div className="hero text set2">
         <div className="mt-auto mb-5">
         <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <span className={`${letterClass} _15`}> </span>
            <span className="name">
            <Link className="logo" to="/">
            <span>C</span>
      </Link>
            </span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={16}
            />
            <br />
            <div className="d-flex flex-wrap ">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray1}
                idx={21}
              />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray2}
                idx={30}
              />
            </div>
          </h1>
          <h2>Software Developer</h2>
         </div>
          <div className="d-flex flex-wrap gap-2">
          <Link to="/about" className="flat-button me-4 mac-button">
            ABOUT ME
          </Link>
          <Link to="/contact" className="flat-button mac-button">
            CONTACT ME
          </Link>
          </div>
        </div>
        <div className="hero text set3">
         <div className="mt-auto mb-5">
         <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <span className={`${letterClass} _15`}> </span>
            <span className="name">
            <Link className="logo" to="/">
            <span>C</span>
      </Link>
            </span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={16}
            />
            <br />
            <div className="d-flex flex-wrap ">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray1}
                idx={21}
              />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray2}
                idx={30}
              />
            </div>
          </h1>
          <h2>Software Developer</h2>
         </div>
          <div className="d-flex flex-wrap gap-2">
          <Link to="/about" className="flat-button me-4">
            ABOUT ME
          </Link>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
          </div>
        </div>
        </div>
      </div>
      <LoaderPulse />
    </>
  );
}

export default Home;

