import "./index.scss";
import AnimatedLetters from "../AnimatedLetters";
import { useEffect, useState } from "react";
import netLogo from "../../assets/images/net.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import LoaderPulse from "../Loader";

function About() {
  const [letterClass, setLetterClass] = useState("text-animate");
  const abouttArray = "About Me".split("");

  useEffect(() => {
    document.title = 'Cagdas Aydemir - About me';
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
    if (window.innerWidth >= 768) {
      setTimeout(() => {
        const cube = document.querySelector(".cube-container");
        const page = document.querySelector(".page");
        const windowWidth = window.innerWidth / 20;
        const windowHeight = window.innerHeight / 10;

        page.addEventListener("mousemove", (e) => {
          const mouseX = e.clientX / windowWidth;
          const mouseY = e.clientY / windowHeight;
          cube.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
        });
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="page about-page ">
        <section className="cube-section">
          <div className="cube-container">
            {" "}
            <div className="cubespinner">
              <div className="face1" color="#F06529">
           <img className=""  src={netLogo} alt=".net logo" />
              </div>
              <div className="face2">
                <FontAwesomeIcon icon={faHtml5} color="#F06529" />
              </div>
              <div className="face3">
                <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
              </div>
              <div className="face4">
                <FontAwesomeIcon icon={faReact} color="#28A4D9" />
              </div>
              <div className="face5">
                <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
              </div>
              <div className="face6">
                <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
              </div>
            </div>
          </div>
        </section>
        <div className="text-zone mb-5">
          <div className="headers">
          <h1>
            <AnimatedLetters
              letterClass={letterClass +" text"}
              strArray={abouttArray}
              idx={15}
            />
          </h1>
          
          </div>
          <p>
  I'm a versatile full-stack developer with a strong foundation in web development, front-end and back-end frameworks, databases, version control, and cloud computing. I specialize in developing comprehensive digital solutions using the latest technologies, and I'm always eager to tackle challenging and diverse projects.
</p>
<p>
  My journey has included key roles in developing full-stack solutions for various business needs, ensuring seamless communication between front-end and back-end systems. My past experiences shaped my holistic approach to project management and design, encompassing multidisciplinary global work distribution and digital transformation initiatives.
</p>
<p>
  Driven by a passion for innovative and game-changing technologies, I'm continuously expanding my knowledge to stay at the forefront of industry trends. My certifications in digital transformation and marketing management, along with Azure Fundamentals, reflect my commitment to growth.
</p>
<p>
  If you're looking for a dedicated and adaptable developer who is eager to deliver impactful solutions, let's connect and explore how I can contribute to your team's success.
</p>

        </div>
      </div>
      <LoaderPulse />
    </>
  );
}

export default About;
