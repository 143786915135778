import "./index.scss";
import AnimatedLetters from "../AnimatedLetters";
import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import LoaderPulse from "../Loader";

function Contact() {
  const [letterClass, setLetterClass] = useState("text-animate");
  const refForm = useRef();
  const contactArray = "Contact Me".split('');

  useEffect(() => {
    document.title = 'Cagdas Aydemir - Contact Me';
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        refForm.current,
        process.env.REACT_APP_EMAILJS_CODE
      )
      .then(
        (result) => {
          alert("Your Message was sent succesfully!");
          window.location.reload(false);
        },
        (error) => {
          alert(error.text);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);

  return (
    <>
      <div className="page contact-page">
        <section className="form-section ">
          <div className="headers">
          <h1>
            <AnimatedLetters
                  // letterClass={letterClass +" text set1"}
                  letterClass={letterClass}
              strArray={contactArray}
              idx={15}
            />
          </h1>
          {/* <h1>
            <AnimatedLetters
                   letterClass={letterClass +" text set2"}
              strArray={contactArray}
              idx={15}
            />
          </h1>
          <h1>
            <AnimatedLetters
                      letterClass={letterClass +" text set3"}
              strArray={contactArray}
              idx={15}
            />
          </h1> */}
          </div>
          <p>
  I'm interested in freelance opportunities, particularly ambitious or large-scale projects. If you have any questions or requests, or if you'd like to discuss a potential collaboration, please don't hesitate to reach out using the form below.
</p>
<div className="contact-form mac-form">
  <form ref={refForm} onSubmit={sendEmail}>
    <ul className="form-list">
                <li className="half">
                  <input type="text" name="from_name" placeholder="Name" required />
                </li>
                <li className="half">
                  <input
                    type="email"
                    name="from_email"
                    placeholder="Email"
                    required
                  />
                </li>
                <li>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </li>
                <li>
                  <textarea name="message" placeholder="Message" required />
                </li>
                <li>
                  <input type="submit" className="flat-button mac-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
          <div className="contact-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linkedin.com/in/cagdasaydemir"
            >
              <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/cagdasaydemir"
            >
              <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
            </a>
          </div>

        </section>
        <section className="map-section">
          <div className="info-map">
            Cagdas Aydemir<br />
            Software Developer<br /><br />
            Atakoy, Bakirkoy<br />
            Istanbul, Turkey, 34158<br />
            <span>ac.cagdasaydemir@gmail.com</span>
          </div>
          <div className="map-wrap">
          <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d192697.88850680026!2d28.84737605381905!3d41.00546324249625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1715101483875!5m2!1sen!2str"
  
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Map of Istanbul"
    ></iframe>
          </div>

        </section>

      </div>
      <LoaderPulse />
    </>
  );
}

export default Contact;
