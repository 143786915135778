import React from 'react'
import Loader from "react-loaders";

const LoaderPulse = () => {
    return (
        <>
            <Loader type="line-scale-pulse-out" />
        </>
    )
}

export default LoaderPulse