import React from "react";
import "./index.scss";
import { Link, NavLink } from "react-router-dom";
import LogoS from "../../assets/images/c-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  return (
    <div className="navbar">
      <Link className="logo" to="/">

     <span>C</span>
      </Link>
      {/* <Link className="logo" to="/">
    
      C
      </Link>
      <Link className="logo" to="/">
       
      C
      </Link> */}
      <section className="navs">
        <NavLink className=" " exact="true" activeclassname="active" to="/" end>
          <FontAwesomeIcon icon={faHome}/>
        </NavLink>
        <NavLink activeclassname="active" className="about-link" to="/about">
          <FontAwesomeIcon icon={faUser} />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </NavLink>
      </section>
      <section className="links">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://linkedin.com/in/cagdasaydemir"
        >
          <FontAwesomeIcon icon={faLinkedin}  />
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/cagdasaydemir"
        >
          <FontAwesomeIcon icon={faGithub}  />
        </a>
      </section>
    </div>
  );
};

export default Navbar;
